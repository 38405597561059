import Alpine from 'alpinejs'

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

window.Alpine = Alpine
Alpine.start()


const swiperHome = new Swiper('.homeSlider', {
    modules: [Autoplay],
    loop: true,
    slidesPerView: 1,
    autoplay: {
        delay: 5000, // Default duration (can be customized per slide)
        disableOnInteraction: false,
    },
});

const reviews = new Swiper('.reviews', {
    modules: [Autoplay, Pagination],
    loop: true,
    slidesPerView: 1,
    spaceBetween: 24,
    // Responsive breakpoints
    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 24
        }
    },
    autoplay: {
        delay: 5000, // Default duration (can be customized per slide)
        disableOnInteraction: false,
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
});


const swiper = new Swiper(".mySwiper", {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
        768: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        1024: {
            slidesPerView: 5,
            spaceBetween: 50,
        },
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

const lightboxGallery = GLightbox({
    selector: '.glightbox-gallery'
});

const openGalleryButtons = document.querySelectorAll('.open-gallery');

// Ajouter un écouteur d'événement à chaque bouton
openGalleryButtons.forEach(button => {
    button.addEventListener('click', function(event) {
        event.preventDefault();
        lightboxGallery.open(); // Ouvrir la galerie au clic sur le bouton
    });
});

Array.from(document.querySelectorAll("[data-lightbox]")).forEach(element => {
    element.onclick = (e) => {
        e.preventDefault();
        basicLightbox.create(`<img src="${element.href}">`).show();
    };
});